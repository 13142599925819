import { trackingLiterals } from '../setup/tracking/trackingLiterals'
import { LENGTH_ONE_ELEMENT, NAPayment } from './constants'
import { Constants } from 'f1-utils'
const {
  STATUSES: { BILL_STATUSES },
} = Constants

export const getBillResults = (billingHistoryData) => {
  return (
    billingHistoryData?.currentSubscriber?.billingHistory?.billResults || {
      bills: {},
    }
  )
}

export const getOldestUnpaidBill = (bills) => {
  if (!bills.length) return {}
  let unpaidBills = Object.keys(bills).map((index) => bills[index])
  unpaidBills.reverse()
  unpaidBills = unpaidBills.filter((billItem) => {
    return (
      billItem.status === BILL_STATUSES.declined.toUpperCase() ||
      billItem.status === BILL_STATUSES.payment_failed.toUpperCase()
    )
  })
  return unpaidBills[0]
}

export const hasTwoOrMoreUnpaidBills = (bills) => {
  if (!bills.length) return false
  let unpaidBills = Object.keys(bills).map((index) => bills[index])
  unpaidBills.reverse()
  unpaidBills = unpaidBills.filter((billItem) => {
    return (
      billItem.status === BILL_STATUSES.declined.toUpperCase() ||
      (billItem.status === BILL_STATUSES.payment_failed.toUpperCase() &&
        billItem.billReferenceNumber !== NAPayment)
    )
  })
  return unpaidBills?.length > 0 || false
}

export const isBillOldestUnpaid = (bills, bill) => {
  if (!bills.length || !bill.id) return false
  let unpaidBills = Object.keys(bills).map((index) => bills[index])
  unpaidBills.reverse()
  unpaidBills = unpaidBills.filter((billItem) => {
    return billItem.status === BILL_STATUSES.declined.toUpperCase()
  })
  return unpaidBills.length > 0 && unpaidBills[0].id === bill.id
}

export const isBillWithError = (billStatus) => {
  const isError =
    billStatus === BILL_STATUSES.declined.toUpperCase() ||
    billStatus === BILL_STATUSES.payment_failed.toUpperCase()

  return isError
}

export const isBillDeclined = (billStatus) => {
  const isDeclined = billStatus === BILL_STATUSES.declined.toUpperCase()

  return isDeclined
}

export const isAnyTransactionFailed = (billHistory) => {
  const failedTransaction = billHistory?.find(
    (bill) => bill.status === BILL_STATUSES.declined.toUpperCase()
  )

  if (failedTransaction) {
    return true
  }

  return false
}

export const findCurrentCard = (cards, paymentMethodId) => {
  return cards?.find((element) => element.id === paymentMethodId)
}

export const checkIsDefaultCard = (defaultCard, currentCard) => {
  return defaultCard && defaultCard?.id === currentCard?.id
}

export const checkIsOnlyCard = (cards) => {
  return cards && cards.length === LENGTH_ONE_ELEMENT
}

export const getRenewFailedStatus = (subscription, bills) => {
  return isAnyTransactionFailed(bills) && subscription?.isRenewable
}

export const getTransactionFailed = (bills) => {
  return bills?.find(
    (bill) => bill.status === BILL_STATUSES.declined.toUpperCase()
  )
}

export const getLastBillStatus = (bills) => {
  if (!bills || bills.length === 0) {
    return undefined
  }
  return bills[0]?.status === BILL_STATUSES.declined.toUpperCase()
    ? trackingLiterals.events.payment_status.failed
    : trackingLiterals.events.payment_status.paid
}
